<template>
  <!-- 历史记录 -->
  <div id="questionList">
    <div class="questions" v-for="(question, index) in listData" :key="index">
      <div class="descriptions">
        <div class="title">{{ question.question }}</div>
        <div class="acceptButton">
          <el-button
            v-if="question.status && question.status.value == 1"
            round
            :class="['acceptBtn', `status${question.status.value}`]"
            >待审核</el-button
          >
          <el-button
            v-if="question.status && question.status.value == 2"
            round
            :class="['acceptBtn', `status${question.status.value}`]"
            >已通过</el-button
          >
          <el-button
            v-if="question.status && question.status.value == 3"
            round
            :class="['acceptBtn', `status${question.status.value}`]"
            >失败</el-button
          >
          <el-button
            v-if="question.status && question.status.value == 4"
            round
            :class="['acceptBtn', `status${question.status.value}`]"
            >已失效</el-button
          >
        </div>
      </div>
      <div class="reason" v-if="question.status && question.status.value == 3">
        失败原因:
        {{ question.reason }}
      </div>
    </div>
    <el-pagination
      v-if="total > 10"
      style="padding: 20px; text-align: center"
      :current-page.sync="currentPage"
      :total="total"
      layout="prev, pager, next"
      @current-change="getMyQuestionHistoryList"
    >
    </el-pagination>
  </div>
</template>
<script>
import { getMyQuestionHistoryList } from "@/service/answerCenter";
export default {
  name: "list",
  data() {
    return {
      listData: [],
      total: 0,
      currentPage: 1,
      showIndex: 0,
      currentQuestion: {},
      detailVisible: false,
    };
  },
  mounted() {
    this.getMyQuestionHistoryList();
  },
  methods: {
    getMyQuestionHistoryList(page) {
      if (page) {
        this.currentPage = page;
      } else {
        this.currentPage = 1;
      }
      getMyQuestionHistoryList(this.currentPage).then((res) => {
        if (res.success) {
          this.listData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.questions {
  padding: 30px 80px;
  margin-bottom: 8px;
  background: #fff;
  .descriptions {
    display: flex;
    flex-wrap: nowrap;
  }
  .title {
    width: 90%;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #34495e;
    line-height: 18px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .acceptButton {
    width: 10%;
    .acceptBtn {
      border: 1px solid rgba(0, 117, 246, 1);
      color: rgba(0, 117, 246, 1);
      padding: 10px 24px;
      cursor: unset;
      &.status1 {
        color: rgba(250, 100, 0, 1);
        border-color: rgba(250, 100, 0, 1);
      }
      &.status2 {
        color: rgba(45, 166, 65, 1);
        border-color: rgba(45, 166, 65, 1);
      }
      &.status3 {
        color: rgba(224, 32, 32, 1);
        border-color: rgba(224, 32, 32, 1);
      }
      &.status4 {
        color: rgba(109, 114, 120, 1);
        border-color: rgba(109, 114, 120, 1);
      }
    }
  }
}
::v-deep .el-dialog {
  border-radius: 12px;
  padding: 12px 60px;
}
::v-deep .el-dialog__header {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    width: 3px;
    height: 20px;

    background: #0084ff;
    border-radius: 2px;
    left: 10px;
    top: 22px;
  }
}
::v-deep .el-dialog__headerbtn {
  font-size: 18px;
  right: -20px;
  top: 10px;
}
.reason {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #e02020;
  line-height: 14px;
  margin-top: 8px;
}
</style>